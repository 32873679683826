export const sizesRu = [
  {
    name: '1:1',
    width: 512,
    height: 512,
  },
  {
    name: '1:1',
    width: 2048,
    height: 2048,
  },
  {
    name: '3:4',
    width: 1200,
    height: 1600,
  },
  // {
  //   name: 'WB, Ozon - 3:4',
  //   width: 1200,
  //   height: 1600,
  // },
  {
    name: '4:3',
    width: 1280,
    height: 960,
  },
];
