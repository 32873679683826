export const sizesEn = [
  {
    name: '512 - 1:1',
    width: 512,
    height: 512,
  },
  {
    name: 'Shopify - 1:1',
    width: 2048,
    height: 2048,
  },
  {
    name: 'Amazon, Lazada, Tokopedia, Instagram post - 1:1',
    width: 1000,
    height: 1000,
  },
  {
    name: 'Shopee - 1:1',
    width: 1024,
    height: 1024,
  },
  {
    name: 'eBay - 1:1',
    width: 1600,
    height: 1600,
  },
  {
    name: 'Walmart - 1:1',
    width: 2000,
    height: 2000,
  },
  {
    name: 'MercadoLibre - 1:1',
    width: 500,
    height: 500,
  },
  // {
  //   name: 'Instagram post - 1:1',
  //   width: 1000,
  //   height: 1000,
  // },
  {
    name: 'Instagram post 4:5',
    width: 1080,
    height: 1350,
  },
  {
    name: 'Instagram story - 9:16',
    width: 1080,
    height: 1920,
  },

];
