import i18n from '@/plugins/i18n';
import { createApp } from 'vue';
// import 'vuetify/styles';
import '@/assets/styles/vuetify/elements/main.sass';
import '@/assets/styles/vuetify/main.scss';
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import InlineSvgPlugin from 'vue-inline-svg';
import Toaster from '@incuca/vue3-toaster';
import { createPinia } from 'pinia';
import { ColorPicker } from 'vue-accessible-color-picker';
import App from './App.vue';

import './assets/styles/fonts.scss';
import './assets/styles/main.scss';
import './assets/styles/overrides.scss';
import './assets/styles/mixins.scss';
import './assets/styles/variables.scss';

const pinia = createPinia();

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#000000',
    secondary: '#ffffff',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
};

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

createApp(App)
  .use(i18n)
  .use(vuetify)
  .use(Toaster)
  .use(pinia)
  .component('inline-svg', InlineSvgPlugin)
  .component('color-picker', ColorPicker)
  .mount((<any>window).AI_WIDGET_SETTINGS ? (<any>window).AI_WIDGET_SETTINGS?.el : '#app');

// test
